<template>
    <div>
        <input-dropdown
            v-for="(d, index) in dropdowns"
            :key="d.questionId"
            v-model="filters[d.questionId]"
            :class="index > 0 ? 'ml-3' : ''"
            :items="d.items"
            toggle-class="bg-white btn-min-width"
            variant="light"
        />
    </div>
</template>

<script>
import InputDropdown from '@/components/common/InputDropdown';

export default {
    name: 'GroupQuestionsFilters',
    components: {InputDropdown},
    props: {
        questions: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            filters: this.createFilters(),
        };
    },
    computed: {
        dropdowns() {
            return this.questions.map((q, index) => {
                let items = q.groupQuestionAnswers.map(a => {
                    return {
                        label: a.answer,
                        value: a.id,
                    };
                });

                // These labels are hardcoded and should be changed once the
                // API returns these "short" names for the questions. These do
                // not have to be translated since they should be user input.
                const labels = [
                    'All departments', 'All locations', 'All transports',
                ];

                return {
                    questionId: q.id,
                    items: [
                        {
                            label: labels[index],
                            value: undefined,
                        },
                        ...items,
                    ],
                };
            });
        },
    },
    watch: {
        questions: {
            deep: true,
            handler() {
                this.filters = this.createFilters();
            },
        },
        filters: {
            deep: true,
            handler(filters) {
                this.$emit('filters-change', filters);
            },
        },
    },
    methods: {
        createFilters() {
            return Object.fromEntries(
                new Map(this.questions.map(q => [q.id, undefined])),
            );
        },
    },
};
</script>
