import store from '@/store';
import {pullAll} from 'lodash';
import Model from '@/models/Model';
import GroupUser from '@/models/GroupUser';
import Measurement from '@/models/Measurement';
import Role from '@/library/enumerations/Role';
import GroupQuestion from '@/models/GroupQuestion';

export default class Group extends Model {
    /**
     * The api endpoint of the model.
     */
    static get endpoint() {
        return 'groups';
    }

    /**
     * The fields that can be filled of the model.
     */
    static get fillables() {
        return ['name', 'slots', 'invitations'];
    }

    /**
     * The fields that can be filled of the instantiated model.
     */
    get fillables() {
        let fillables = this.constructor.fillables;

        // Temporary: disallow `invitations` edit because no design yet.
        if (this.primaryKey) {
            pullAll(fillables, ['invitations']);
        }

        // Disallow non-admin to fill `slots`.
        if (!store.state.auth.me.hasRoles(Role.ADMIN)) {
            pullAll(fillables, ['slots']);
        }

        return fillables;
    }

    /**
     * Returns the endpoint of this group's group questions.
     */
    get groupQuestionsEndpoint() {
        return `${this.endpoint}/${GroupQuestion.endpoint}`;
    }

    /**
     * Returns the endpoint of this group's group users.
     */
    get groupUsersEndpoint() {
        return `${this.endpoint}/${GroupUser.endpoint}`;
    }

    /**
     * Returns the endpoint of this group's measurements.
     */
    get measurementsEndpoint() {
        return `${this.endpoint}/${Measurement.endpoint}`;
    }
}
