import Model from '@/models/Model';

export default class GroupQuestion extends Model {
    /**
     * The api endpoint of the model.
     */
    static get endpoint() {
        return 'group-questions';
    }
}
