import Model from '@/models/Model';
import Role from '@/library/enumerations/Role';

export default class GroupUser extends Model {
    /**
     * The api endpoint of the model.
     */
    static get endpoint() {
        return 'group-users';
    }

    /**
     * Translate and concatenate roles into a string.
     */
    get rolesAsString() {
        return Role.rolesAsString(this.roles);
    }
}
