<template>
    <div>
        <groups-statistics-header :group="group" />

        <b-row
            align-h="between"
            align-v="center"
            class="mb-4"
        >
            <b-col cols="auto">
                <span class="mr-4">
                    {{ $t('groups.statistics.filters') }}
                </span>

                <wait-for-request
                    v-if="group"
                    class="d-inline-block"
                    :request="questionsRequest"
                >
                    <group-question-filters
                        class="d-inline-block"
                        :questions="questions"
                        @filters-change="onQuestionFiltersChange"
                    />
                </wait-for-request>
            </b-col>

            <!-- Disable for now -->
            <!-- <b-col cols="auto">
                    {{ $t('groups.statistics.includedGroupUsers') }}: <strong>0</strong>
                </b-col> -->
        </b-row>

        <b-card>
            <b-row
                align-h="between"
                align-v="center"
            >
                <b-col cols="auto">
                    <b-btn-group>
                        <b-btn
                            v-for="p in periods"
                            :key="p"
                            v-t="`groups.statistics.periods.${p}`"
                            :class="p === period ? 'active' : ''"
                            variant="link"
                            @click="onClickPeriod(p)"
                        />
                    </b-btn-group>
                </b-col>
            </b-row>

            <wait-for-request
                class="d-flex align-items-center justify-content-center"
                :request="measurementsRequest"
                :style="{ height: chartHeight }"
            >
                <line-chart
                    class="w-100 position-relative"
                    :period="period"
                    :styles="{ height: chartHeight }"
                    :values="values"
                />
            </wait-for-request>
        </b-card>
    </div>
</template>

<script>
import moment from 'moment';
import Group from '@/models/Group';
import {isEqual, sortBy} from 'lodash';
import Measurement from '@/models/Measurement';
import GroupQuestion from '@/models/GroupQuestion';
import LineChart from '@/components/measurements/LineChart';
import GroupQuestionFilters from '@/components/groupQuestions/Filters';
import GroupsStatisticsHeader from '@/components/groups/statistics/Header';

export default {
    name: 'GroupsStatisticsIndex',
    components: {LineChart, GroupQuestionFilters, GroupsStatisticsHeader},
    props: {
        group: {
            type: Group,
            required: true,
        },
    },
    data() {
        return {
            chartHeight: '350px',
            filters: {
                from: moment().startOf('day').toString(),
                to: moment().endOf('day').toString(),
                answer_ids: [], // API requires to be in snake_case.
            },
            measurements: [],
            measurementsRequest: Measurement.get(),
            period: 'day',
            periods: ['day', 'week', 'month'],
            questions: [],
            questionsRequest: GroupQuestion.paginate(),
        };
    },
    computed: {
        values() {
            return this.measurements.map((m) => {
                return {
                    x: m.measuredAt,
                    y: m.stress,
                };
            });
        },
    },
    async created() {
        this.measurementsRequest.loading = true;

        this.questions = (await this.questionsRequest.submit({
            url: this.group.groupQuestionsEndpoint,
        })).data;

        this.measurementsRequest.options.url = this.group.measurementsEndpoint;

        this.measurementsRequest.options.params = this.filters;

        this.getMeasurements();
    },
    methods: {
        async getMeasurements() {
            this.measurements = await this.measurementsRequest.submit();
        },
        onClickPeriod(period) {
            if (this.period === period) {
                return;
            }

            this.filters.from = moment().startOf(period).toString();

            this.filters.to = moment().endOf(period).toString();

            this.getMeasurements();

            this.period = period;
        },
        onQuestionFiltersChange(filters) {
            const answerIds = Object.values(filters).filter(Number);

            if (!isEqual(sortBy(this.filters.answer_ids), sortBy(answerIds))) {
                this.getMeasurements();

                this.filters.answer_ids = answerIds;
            }
        },
    },
};
</script>
