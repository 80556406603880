<script>
import moment from 'moment';
import {merge} from 'lodash';
import {Line} from 'vue-chartjs';
import StressLevel from '@/library/enumerations/StressLevel';

const lineWidth = 6;

const minY = -.2;

const maxY = 2.1;

export default {
    name: 'MeasurementsLineChart',
    extends: Line,
    props: {
        period: {
            type: String,
            default: 'day',
            validator(value) {
                return ['day', 'week', 'month'].includes(value);
            },
        },
        values: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            chartData: {
                datasets: [
                    {
                        borderColor: '#00afe0',
                        borderCapStyle: 'round',
                        borderWidth: lineWidth,
                        fill: false,
                        pointBorderWidth: 0,
                        pointRadius: 0,
                    },
                    {
                        borderColor: 'rgba(0, 0, 0, 0)',
                        borderWidth: 0,
                        fill: false,
                        pointRadius: lineWidth / 2,
                    },
                ],
            },
            options: {
                layout: {
                    padding: {
                        // Because of removing legend half of the borderWidth
                        // of drawn line should be padding to prevent the line
                        // to have some kind of overflow hidden effect at the
                        // top of the canvas.
                        left: lineWidth / 2,
                    },
                },
                legend: {
                    display: false,
                },
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        gridLines: {
                            drawBorder: false,
                            drawTicks: false,
                            lineWidth: 2,
                            zeroLineWidth: 2,
                        },
                        ticks: {
                            padding: 20,
                        },
                        time: {
                            displayFormats: {
                                hour: 'HH:mm',
                            },
                        },
                        type: 'time',
                    }],
                    yAxes: [{
                        display: false,
                        ticks: {
                            max: maxY,
                            min: minY,
                            beginAtZero: true,
                        },
                    }],
                },
                responsive: true,
                tooltips: {
                    enabled: false,
                },
            },
            updateChartTimeout: null,
            xAxisLineGradient: null,
        };
    },
    computed: {
        dotsColors() {
            return this.values.map((v) => {
                return StressLevel.getColor(Math.round(v.y));
            });
        },
        dotsData() {
            return this.values.map(v => {
                return {x: v.x, y: minY};
            });
        },
        timeConfig() {
            const min = moment().startOf(this.period);

            let config = {
                min,
                max: moment().endOf(this.period),
                unit: 'hour',
            };

            if (this.period === 'month' || this.period === 'week') {
                config.unit = 'day';

                config.max.startOf('day');
            } else if (this.period === 'day') {
                config.max.startOf('hour');
            }

            return config;
        },
    },
    watch: {
        values: {
            deep: true,
            handler() {
                this.startUpdateChartTimeout();
            },
        },
        period() {
            this.startUpdateChartTimeout();
        },
    },
    mounted() {
        this.createXAxisLineGradient();

        this.startUpdateChartTimeout();
    },
    beforeDestroy() {
        clearTimeout(this.updateChartTimeout);
    },
    methods: {
        createXAxisLineGradient() {
            this.xAxisLineGradient = this.$refs.canvas
                .getContext('2d')
                .createLinearGradient(0, 0, 0, 1000);

            this.xAxisLineGradient.addColorStop(.1, 'rgba(0, 0, 0, 0)');
            this.xAxisLineGradient.addColorStop(.3, 'rgba(0, 0, 0, .05)');
            this.xAxisLineGradient.addColorStop(1, 'rgba(0, 0, 0, .1)');
        },
        startUpdateChartTimeout() {
            clearTimeout(this.updateChartTimeout);

            this.updateChartTimeout = setTimeout(() => this.updateChart(), 100);
        },
        updateChart() {
            const chartData = {
                datasets: [
                    {
                        data: this.values,
                    },
                    {
                        data: this.dotsData,
                        pointBackgroundColor: this.dotsColors,
                    },
                ],
            };

            const options = {
                scales: {
                    xAxes: [{
                        gridLines: {
                            color: this.xAxisLineGradient,
                            zeroLineColor: this.xAxisLineGradient,
                        },
                        ticks: {
                            max: this.timeConfig.max,
                            min: this.timeConfig.min,
                        },
                        time: {
                            unit: this.timeConfig.unit,
                        },
                    }],
                },
            };

            this.renderChart(
                merge(this.chartData, chartData),
                merge(this.options, options),
            );
        },
    },
};
</script>
