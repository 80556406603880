<template>
    <portal to="layout-default-header">
        <b-row align-v="center">
            <svg-circle class="stroke-light position-absolute top-right" />

            <b-col cols="auto mr-auto">
                <h1>
                    {{ $t('groups.statistics.title') }}
                </h1>
            </b-col>

            <b-col cols="auto">
                <h1 v-if="group">
                    {{ group.groupUsersCount }}
                </h1>

                <p class="mb-0 small text-muted">
                    {{ $t('groups.fields.active') }}
                </p>
            </b-col>

            <b-col cols="auto">
                <h1 v-if="group">
                    {{ group.slots }}
                </h1>

                <p class="mb-0 small text-muted">
                    {{ $t('groups.fields.slots') }}
                </p>
            </b-col>

            <!-- Hidden for now because this is unquoted. -->
            <!-- <b-col
                    cols="auto"
                    class="ml-md-3"
                >
                    <b-button
                        class="btn-min-width"
                        variant="primary"
                    >
                        Download as PDF
                    </b-button>
                </b-col> -->
        </b-row>
    </portal>
</template>

<script>
import Group from '@/models/Group';
import Request from '@/library/http/Request';
import SvgCircle from '@/components/common/SvgCircle';

export default {
    name: 'GroupsStatisticsHeader',
    components: {SvgCircle},
    props: {
        group: {
            type: Group,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.top-right {
    right: 0;
    bottom: 0;
    transform: translate(75%, -5%);
}
</style>
