import Model from '@/models/Model';

export default class Measurement extends Model {
    /**
     * The api endpoint of the model.
     */
    static get endpoint() {
        return 'measurements';
    }

    /**
     * The fields that can be filled of the model.
     */
    static get fillables() {
        return [
            'user_id', 'activity', 'stress', 'temperature',
            'battery_percentage', 'measured_at',
        ];
    }
}
