import Enumeration from './Enumeration';

export default class StressLevel extends Enumeration {
    static RELAXED = 0;
    static NORMAL = 1;
    static STRESSFUL = 2;

    static colors = {
        0: '#cfd1d3',
        1: '#00de80',
        2: '#ff037b',
    };

    /**
     * @returns {Object} Enumeration key-value pairs of the Enum.
     */
    static get enumeration() {
        return {
            RELAXED,
            NORMAL,
            STRESSFUL,
        };
    };

    static getColor(level) {
        return this.colors[level];
    }
}
